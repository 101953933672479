import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ErrorTriangleOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6498 14.1216C12.6498 14.4805 12.3587 14.7716 11.9998 14.7716C11.6408 14.7716 11.3498 14.4805 11.3498 14.1216V8.97536C11.3498 8.61637 11.6408 8.32536 11.9998 8.32536C12.3587 8.32536 12.6498 8.61637 12.6498 8.97536V14.1216Z"
        fill={color}
      />
      <path
        d="M12.7498 16.8753C12.7498 17.2896 12.414 17.6253 11.9998 17.6253C11.5855 17.6253 11.2498 17.2896 11.2498 16.8753C11.2498 16.4611 11.5855 16.1253 11.9998 16.1253C12.414 16.1253 12.7498 16.4611 12.7498 16.8753Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.838 21H5.16189C2.86012 21 1.41582 18.5148 2.55527 16.5149L9.39331 4.51283C10.5441 2.49296 13.4558 2.49296 14.6066 4.51283L21.4446 16.5149C22.584 18.5148 21.1397 21 18.838 21ZM18.838 19.7H5.16189C3.85756 19.7 3.03911 18.2917 3.6848 17.1584L10.5228 5.15637C11.175 4.01178 12.8249 4.01178 13.477 5.15637L20.3151 17.1584C20.9607 18.2917 20.1423 19.7 18.838 19.7Z"
        fill={color}
      />
    </svg>
  );
};

const ErrorTriangleSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30166 17.5H15.6984C17.6165 17.5 18.8201 15.429 17.8706 13.7624L12.1722 3.76068C11.2132 2.07745 8.78684 2.07745 7.82784 3.76068L2.12947 13.7624C1.17993 15.429 2.38351 17.5 4.30166 17.5ZM10.5415 11.7729C10.5415 12.0721 10.299 12.3146 9.99988 12.3146C9.70072 12.3146 9.45821 12.0721 9.45821 11.7729V7.48442C9.45821 7.18526 9.70072 6.94275 9.99988 6.94275C10.299 6.94275 10.5415 7.18526 10.5415 7.48442V11.7729ZM10.6249 14.0677C10.6249 14.4129 10.3451 14.6927 9.99988 14.6927C9.6547 14.6927 9.37488 14.4129 9.37488 14.0677C9.37488 13.7226 9.6547 13.4427 9.99988 13.4427C10.3451 13.4427 10.6249 13.7226 10.6249 14.0677Z"
        fill={color}
      />
    </svg>
  );
};

export const ErrorTriangleIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ErrorTriangleOutlined}
      solidSvg={ErrorTriangleSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
